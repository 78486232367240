import React, {useEffect, useRef} from 'react';
import * as Video from 'twilio-video';
export function RemoteAudioTrackElement({
  track,
}: {
  track: Video.RemoteAudioTrack;
}) {
  const audioElRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audioElRef.current) {
      track.attach(audioElRef.current);
    }
    return function () {
      track.detach();
    };
  }, [track]);

  return <audio ref={audioElRef}></audio>;
}
