import React, {createContext, ReactNode} from 'react';
import * as Video from 'twilio-video';
import useLocalParticipant from './hooks/useLocalParticipant';
import useParticipants from './hooks/useParticipants';
import usePrimaryVideoPanelRef from './hooks/usePrimaryVideoPanelRef';
import useRemoteAudioTracks from './hooks/useRemoteAudioTracks';
import useRemoteVideoTracks from './hooks/useRemoteVideoTracks';
import useRoom from './hooks/useRoom';
import useSettings from './hooks/useSettings';
import useStreams from './hooks/useStreams';
export interface VideoVisitsContextProps {
  room: Video.Room | null;
  videoStreams: Video.LocalVideoTrack[] | null;
  audioStreams: Video.LocalAudioTrack[] | null;
  localVideoStreamEnabled: boolean;
  localAudioStreamEnabled: boolean;
  handleLocalVideoTrack: () => void;
  handleLocalAudioTrack: () => void;
  disconnectStreams: () => void;
  startLocalStream: () => Promise<void>;
  participants: Video.RemoteParticipant[];
  addParticipant: (participant: Video.RemoteParticipant) => void;
  removeParticipant: (participant: Video.RemoteParticipant) => void;
  connectParticipant: (
    participant: Video.RemoteParticipant
  ) => Video.RemoteParticipant;
  currentVoiceCallSid: string;
  setCurrentVoiceCallSid: React.Dispatch<React.SetStateAction<string>>;
  connecting: boolean;
  connected: boolean;
  callStart: number;
  connect: (token: string) => Promise<void>;
  disconnectRoom: () => void;
  remoteVideoTracks: Map<string, Video.RemoteVideoTrack>;
  primaryVideoPanelRef: HTMLVideoElement | null;
  setPrimaryVideoPanelRef: React.Dispatch<
    React.SetStateAction<HTMLVideoElement | null>
  >;
  remoteAudioTracks: Map<string, Video.RemoteAudioTrack>;
  settingsPanelOpen: boolean;
  setSettingsPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMicrophone: string;
  setSelectedMicrophone: React.Dispatch<React.SetStateAction<string>>;
  selectedCamera: string;
  setSelectedCamera: React.Dispatch<React.SetStateAction<string>>;
  localParticipant: Video.LocalParticipant | null;
  addLocalParticipant: (localParticipant: Video.LocalParticipant) => void;
}

export const VideoVisitsContext = createContext<VideoVisitsContextProps>(null!);
interface VideoVisitsContextProviderProps {
  children: ReactNode;
}

export function VideoVisitsProvider({
  children,
}: VideoVisitsContextProviderProps) {
  const {
    participants,
    audioParticipants,
    addParticipant,
    removeParticipant,
    connectParticipant,
    currentVoiceCallSid,
    setCurrentVoiceCallSid,
  } = useParticipants();

  const {primaryVideoPanelRef, setPrimaryVideoPanelRef} =
    usePrimaryVideoPanelRef();

  const {remoteAudioTracks} = useRemoteAudioTracks([
    ...participants,
    ...audioParticipants,
  ]);
  const {remoteVideoTracks} = useRemoteVideoTracks(
    participants,
    primaryVideoPanelRef
  );

  const {
    settingsPanelOpen,
    setSettingsPanelOpen,
    selectedMicrophone,
    setSelectedMicrophone,
    selectedCamera,
    setSelectedCamera,
  } = useSettings();

  const {addLocalParticipant, localParticipant} = useLocalParticipant();

  const {
    videoStreams,
    audioStreams,
    disconnectStreams,
    startLocalStream,
    localVideoStreamEnabled,
    localAudioStreamEnabled,
    handleLocalVideoTrack,
    handleLocalAudioTrack,
  } = useStreams(
    localParticipant,
    participants,
    primaryVideoPanelRef,
    selectedCamera,
    selectedMicrophone
  );

  const {room, connecting, connected, connect, disconnectRoom, callStart} =
    useRoom(
      videoStreams,
      audioStreams,
      addParticipant,
      removeParticipant,
      connectParticipant,
      disconnectStreams,
      addLocalParticipant
    );
  return (
    <VideoVisitsContext.Provider
      value={{
        addLocalParticipant,
        localParticipant,
        videoStreams,
        audioStreams,
        localVideoStreamEnabled,
        localAudioStreamEnabled,
        handleLocalVideoTrack,
        handleLocalAudioTrack,
        room,
        connecting,
        callStart,
        connected,
        connect,
        participants,
        addParticipant,
        removeParticipant,
        currentVoiceCallSid,
        setCurrentVoiceCallSid,
        disconnectStreams,
        startLocalStream,
        disconnectRoom,
        connectParticipant,
        remoteVideoTracks,
        primaryVideoPanelRef,
        setPrimaryVideoPanelRef,
        remoteAudioTracks,
        settingsPanelOpen,
        setSettingsPanelOpen,
        selectedMicrophone,
        setSelectedMicrophone,
        selectedCamera,
        setSelectedCamera,
      }}
    >
      {children}
    </VideoVisitsContext.Provider>
  );
}
