import {useState} from 'react';

/**
 * usePrimaryVideoPanelRef is the Context hook for the
 * primary video panel. It handles storing the element
 * reference for the primary video.
 */
export default function usePrimaryVideoPanelRef() {
  const [primaryVideoPanelRef, setPrimaryVideoPanelRef] =
    useState<HTMLVideoElement | null>(null);

  return {primaryVideoPanelRef, setPrimaryVideoPanelRef};
}
