import React, {useEffect, useState} from 'react';
import useVideoVisitsContext from '../VideoVisits/hooks/useContext';

/**
 * Displays the current primary video stream. The primary video stream
 * is a video stream from a focused participant, if there is one connected.
 * If there are no participants, the video stream from the local user
 * is displayed instead.
 */
export function PrimaryVideoPanel() {
  const {setPrimaryVideoPanelRef, remoteVideoTracks, participants} =
    useVideoVisitsContext();

  const [showVideo, setShowVideo] = useState(true);
  useEffect(() => {
    /**
     * If there are participants, hide the primary video panel
     * if their video tracks are disabled. If there are no participants,
     * show the local user in the primary slot
     */
    if (participants?.length) {
      setShowVideo(remoteVideoTracks.size > 0);
    } else {
      setShowVideo(true);
    }
  }, [participants, remoteVideoTracks]);
  return (
    <div
      style={{
        backgroundColor: '#212121',
        width: '100%',
        minHeight: '600px',
        height: '80%',
        maxHeight: '80vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <video
        style={{
          width: '100%',
          height: '100%',
          maxHeight: '80vh',
        }}
        id='video-visits-test-id'
        ref={ref => setPrimaryVideoPanelRef(ref)}
        hidden={!showVideo}
      />
    </div>
  );
}
