import {MenuItem} from '@mui/material';
import React from 'react';
import {FooterActionPopup} from '../FooterActionPopup';
import {FooterControlMenuProps} from './FooterControlMenu.types';

export function FooterControlMenu({options}: FooterControlMenuProps) {
  return (
    <FooterActionPopup>
      {options.map(option => (
        <MenuItem
          data-testid={option.testId}
          onClick={option.onClick}
          disabled={option.disable}
        >
          {option.label}
        </MenuItem>
      ))}
    </FooterActionPopup>
  );
}
