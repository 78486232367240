import {useState} from 'react';

/**
 * useSettings is the Context hook for the
 * microphone/video camera settings panel. It handles
 * whether or not the panel is open and
 * which microphone/camera is selected.
 */
export default function useSettings() {
  const [settingsPanelOpen, setSettingsPanelOpen] = useState<boolean>(false);

  const [selectedMicrophone, setSelectedMicrophone] = useState<string>('');

  const [selectedCamera, setSelectedCamera] = useState<string>('');

  return {
    settingsPanelOpen,
    setSettingsPanelOpen,
    selectedMicrophone,
    setSelectedMicrophone,
    selectedCamera,
    setSelectedCamera,
  };
}
