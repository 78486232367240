import React, {useEffect} from 'react';
import {Footer} from '../FooterControls';
import {VideoVisitsProps} from './VideoVisits.types';

import * as AutomationIds from '../../automation';
import {AdditionalVideoPanel} from '../AdditionalVideoPanel';
import {PrimaryVideoPanel} from '../PrimaryVideoPanel';
import {RemoteAudioTrackElements} from '../RemoteAudioTrackElements';
import {ResizeActionsToolbar} from '../ResizeActions';
import {SettingsPanel} from '../SettingsPanel';
import useVideoVisitsContext from './hooks/useContext';

export function VideoVisits({
  accessToken,
  onPopoutVideoVisits,
  onResizeVideoVisits,
  expanded,
  onEndVideoVisit,
  onStartVoiceCall,
  onEndVoiceCall,
  voiceCallSid,
}: VideoVisitsProps) {
  const {
    startLocalStream,
    videoStreams,
    handleLocalVideoTrack,
    handleLocalAudioTrack,
    localVideoStreamEnabled,
    localAudioStreamEnabled,
    connect,
    connected,
    connecting,
    disconnectRoom,
    participants,
    settingsPanelOpen,
    setSettingsPanelOpen,
    disconnectStreams,
    currentVoiceCallSid,
    setCurrentVoiceCallSid,
    callStart,
  } = useVideoVisitsContext();
  useEffect(() => {
    startLocalStream();
  }, [startLocalStream]);

  useEffect(() => {
    const connectToRoom = async () => {
      await connect(accessToken);
    };

    if (accessToken && !connected && !connecting && videoStreams) {
      connectToRoom();
    }
  }, [accessToken, connect, connected, connecting, videoStreams]);

  useEffect(() => {
    if (voiceCallSid !== currentVoiceCallSid) {
      setCurrentVoiceCallSid(voiceCallSid);
    }
  }, [voiceCallSid, currentVoiceCallSid, setCurrentVoiceCallSid]);

  return (
    <div
      data-cy='video-visits-container'
      data-testid={AutomationIds.VIDEOVISITS}
      style={{
        height: '100%',
        width: '100%',
        maxHeight: '100vh',
        maxWidth: '100vw',
      }}
    >
      {settingsPanelOpen && <SettingsPanel />}
      <div
        style={{
          position: 'relative',
          top: '25px',
          right: '25px',
          float: 'right',
          height: '0px',
          zIndex: 1,
        }}
      >
        {(onPopoutVideoVisits || onResizeVideoVisits) && (
          <ResizeActionsToolbar
            expanded={expanded}
            onPopoutVideoVisits={
              onPopoutVideoVisits
                ? () => {
                    /**
                     * Disconnect all parts of the video visit,
                     * but do not disconnect the active voice call
                     */
                    disconnectRoom();
                    disconnectStreams();
                    onEndVideoVisit();
                    onPopoutVideoVisits();
                  }
                : undefined
            }
            onResizeVideoVisits={onResizeVideoVisits}
          />
        )}
      </div>
      <PrimaryVideoPanel />
      <div
        style={{
          position: 'relative',
          bottom: '250px',
          right: '25px',
          float: 'right',
          height: '0px',
        }}
      >
        {participants.length > 0 && <AdditionalVideoPanel />}
      </div>
      <RemoteAudioTrackElements />
      <Footer
        handleDisconnect={() => {
          onEndVoiceCall();
          disconnectRoom();
          disconnectStreams();
          onEndVideoVisit();
        }}
        handleCameraControlClick={handleLocalVideoTrack}
        cameraEnabled={localVideoStreamEnabled}
        handleMicrophoneControlClick={handleLocalAudioTrack}
        microphoneEnabled={localAudioStreamEnabled}
        handleSettingsClick={() => setSettingsPanelOpen(true)}
        onStartVoiceCall={onStartVoiceCall}
        voiceCallInSession={voiceCallSid !== ''}
        callStartTime={callStart}
      />
    </div>
  );
}
