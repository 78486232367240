import {Box, ClickAwayListener, Popper} from '@mui/material';
import React, {useState} from 'react';
import * as AutomationIds from '../.././../automation';
import {FooterConfirmAction} from '../FooterConfirmAction';
import {FooterControlMenu} from '../FooterControlMenu/FooterControlMenu';
import {FooterIcon} from '../FooterIcon';
import {FooterControlProps} from './FooterControl.types';

export function FooterControl({
  icon,
  onClick,
  footerConfirmActionProps,
  footerMenuProps,
  backgroundColor,
}: FooterControlProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'footer-control-popper' : undefined;

  function onClickAway() {
    setAnchorEl(null);
  }
  function handleClick(event: React.MouseEvent<HTMLElement>) {
    if (footerConfirmActionProps || footerMenuProps) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else {
      onClick && onClick();
    }
  }
  return (
    <>
      <div
        data-testid={AutomationIds.FOOTER_CONTROL_CLICK_HANDLER}
        onClick={handleClick}
      >
        <FooterIcon icon={icon} backgroundColor={backgroundColor} />
      </div>

      <Popper open={open} id={id} placement={'top'} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={onClickAway}>
          <Box sx={{paddingBottom: '8px'}}>
            {footerMenuProps && <FooterControlMenu {...footerMenuProps} />}
            {footerConfirmActionProps && (
              <FooterConfirmAction
                confirmButtonText={footerConfirmActionProps.confirmButtonText}
                confirmationPrompt={footerConfirmActionProps.confirmationPrompt}
                onCancel={() => {
                  setAnchorEl(null);
                  footerConfirmActionProps.onCancel();
                }}
                onConfirm={() => {
                  setAnchorEl(null);
                  footerConfirmActionProps.onConfirm();
                }}
              />
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
