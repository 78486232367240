import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import {Duration} from 'luxon';
import React, {useEffect, useState} from 'react';
import * as AutomationIds from '../../../automation';
import {FooterControl} from '../FooterControl';
import {FooterProps} from './Footer.types';
import {FooterControlSpacer} from './FooterControlSpacer';

/**
 * TODO(chhil): get real icon URLs
 */
export function Footer({
  handleDisconnect,
  handleCameraControlClick,
  cameraEnabled,
  handleMicrophoneControlClick,
  microphoneEnabled,
  handleSettingsClick,
  onStartVoiceCall,
  voiceCallInSession,
  callStartTime,
}: FooterProps) {
  const [callLength, setCallLength] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCallLength(Date.now() - callStartTime);
    }, 1000);
    return () => clearInterval(interval);
  }, [callStartTime]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '110px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#616161',
        }}
      >
        {/**
         * Disconnect
         */}
        <FooterControl
          backgroundColor='#D4483E'
          icon={<CallEndIcon sx={{color: 'white'}} />}
          footerConfirmActionProps={{
            confirmationPrompt: 'Are you sure you want to hang up?',
            onCancel: () => {
              /**
               * NO-OP
               */
            },
            onConfirm: handleDisconnect,
            confirmButtonText: 'End Call',
          }}
          onClick={handleDisconnect}
        />
        <FooterControlSpacer />
        {/**
         * Enable/Disable video
         */}
        <FooterControl
          icon={cameraEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
          onClick={handleCameraControlClick}
        />
        <FooterControlSpacer />
        {/**
         * Enable/Disable Microphone
         */}
        <FooterControl
          icon={microphoneEnabled ? <MicIcon /> : <MicOffIcon />}
          onClick={handleMicrophoneControlClick}
        />
        <FooterControlSpacer />
        {/**
         * Settings menu and external call trigger
         */}
        <FooterControl
          icon={<MoreVertIcon />}
          footerMenuProps={{
            options: [
              {
                label: 'Settings',
                onClick: handleSettingsClick,
                testId: AutomationIds.OPEN_SETTINGS_MENU,
              },
              {
                label: 'Call Interpreter',
                onClick: onStartVoiceCall,
                testId: AutomationIds.TRIGGER_EXTERNAL_CALL,
                disable: voiceCallInSession,
              },
            ],
          }}
        />
      </div>
      <span
        style={{fontFamily: 'Inter, sans-serif'}}
      >{`Time Elapsed: ${Duration.fromMillis(callLength).toFormat(
        'hh:mm:ss'
      )}`}</span>
    </div>
  );
}
