import React from 'react';
import {RemoteAudioTrackElement} from '../RemoteAudioTrackElement';
import useVideoVisitsContext from '../VideoVisits/hooks/useContext';

export function RemoteAudioTrackElements() {
  const {remoteAudioTracks} = useVideoVisitsContext();

  return (
    <>
      {Array.from(remoteAudioTracks.entries()).map(([sid, track]) => {
        return <RemoteAudioTrackElement key={sid} track={track} />;
      })}
    </>
  );
}
