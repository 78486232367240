// TODO(chhil): use Verily design system button
import {Button} from '@mui/material';
import React from 'react';
import * as AutomationIds from '../../../automation';
import {FooterActionPopup} from '../FooterActionPopup';
import {FooterConfirmActionProps} from './FooterConfirmAction.types';
export function FooterConfirmAction({
  confirmationPrompt,
  confirmButtonText,
  onConfirm,
  onCancel,
}: FooterConfirmActionProps) {
  return (
    <FooterActionPopup>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '130px',
        }}
      >
        {confirmationPrompt}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            data-testid={AutomationIds.FOOTER_CONFIRM_ACTION_CANCEL_BUTTON}
            onClick={onCancel}
            variant='text'
          >
            Cancel
          </Button>

          <Button
            data-testid={AutomationIds.FOOTER_CONFIRM_ACTION_CONFIRM_BUTTON}
            onClick={onConfirm}
            color='error'
            variant='contained'
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    </FooterActionPopup>
  );
}
