import {useCallback, useState} from 'react';
import * as Video from 'twilio-video';
import {Room} from 'twilio-video';

export default function useRoom(
  videoStreams: Video.LocalVideoTrack[] | null,
  audioStreams: Video.LocalAudioTrack[] | null,
  addParticipant: (participant: Video.RemoteParticipant) => void,
  removeParticipant: (participant: Video.RemoteParticipant) => void,
  connectParticipant: (
    participant: Video.RemoteParticipant
  ) => Video.RemoteParticipant,
  disconnectStreams: () => void,
  addLocalParticipant: (localParticipant: Video.LocalParticipant) => void
) {
  const [room, setRoom] = useState<Room | null>(null);
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [callStart, setCallStart] = useState(Date.now());

  const disconnectRoom = useCallback(() => {
    if (room) {
      room.disconnect();
    }
  }, [room]);

  const connect = useCallback(
    async accessToken => {
      if (!connected && !connecting) {
        try {
          setConnecting(true);
          const room = await Video.connect(accessToken, {
            tracks: [...(audioStreams || []), ...(videoStreams || [])],
          });
          addLocalParticipant(room.localParticipant);
          setConnected(true);
          setConnecting(false);

          /**
           * TODO(chhil): log local participant
           */
          const existingParticipants = room.participants;
          existingParticipants.forEach(participant => {
            /**
             * TODO(chhil): log each current participant
             */
            addParticipant(participant);
            connectParticipant(participant);
          });

          room.on('participantConnected', participant => {
            addParticipant(participant);
            connectParticipant(participant);
          });

          room.on('participantDisconnected', participant => {
            removeParticipant(participant);
          });

          room.on('reconnecting', () => {
            /**
             * TODO(chhil): store tracks in store
             */
            disconnectRoom();
          });
          setRoom(room);
          setCallStart(Date.now());
        } catch (e) {
          // NO OP
        }
      }
    },
    [
      connected,
      connecting,
      audioStreams,
      videoStreams,
      addLocalParticipant,
      addParticipant,
      connectParticipant,
      removeParticipant,
      disconnectRoom,
    ]
  );

  return {room, connect, disconnectRoom, connected, connecting, callStart};
}
