import {useCallback, useEffect, useState} from 'react';
import * as Video from 'twilio-video';

/**
 * The Context hook for remote audio tracks. When a participant is added,
 * this hook stores their audio tracks in its state. When a participant is
 * removed, the audio tracks are detached from their elements
 * and removed from the state.
 */
export default function useRemoteAudioTracks(
  participants: Video.RemoteParticipant[]
) {
  const [remoteAudioTracks, setRemoteAudioTracks] = useState<
    Map<string, Video.RemoteAudioTrack>
  >(new Map<string, Video.RemoteAudioTrack>());

  /**
   * Callback function, triggered whenever participants are updated.
   * Handles initializing tracks and their listeners.
   */
  const setupTracks = useCallback(() => {
    participants.forEach(participant => {
      /**
       * Tracks must be both subscribed and enabled to be rendered.
       */
      participant.on('trackSubscribed', (track: Video.RemoteTrack) => {
        if (track.kind === 'audio') {
          /**
           * If the participant joins and their audio is already enabled,
           * render it right away.
           */
          if (track.isEnabled) {
            setRemoteAudioTracks(
              new Map(remoteAudioTracks.set(participant.sid, track))
            );
          }

          /**
           * If the participant joins and their audio is not enabled,
           * we need to wait until it is enabled to render it.
           * Or, if it's initially enabled but then they disable it,
           * we need to pick up the case where they re-enable it
           */
          track.on('enabled', track => {
            setRemoteAudioTracks(
              new Map(remoteAudioTracks.set(participant.sid, track))
            );
          });

          /**
           * When the participant disables their audio through the camera
           * control, we remove the track from state and detach it from the
           * media element it is attached to.
           */
          track.on('disabled', () => {
            const newMap = new Map(remoteAudioTracks);
            newMap.delete(participant.sid);
            setRemoteAudioTracks(newMap);
          });
        }
      });

      /**
       * Remove tracks and detach them from their media elements
       * when they are unsubscribed.
       */
      participant.on('trackUnsubscribed', track => {
        if (track.kind === 'audio') {
          const newMap = new Map(remoteAudioTracks);
          newMap.delete(participant.sid);
          setRemoteAudioTracks(newMap);
        }
      });
    });
  }, [participants, remoteAudioTracks]);

  /**
   * This useEffect is triggered whenever participants are updated,
   * calling setupTracks to handle the new participants
   */
  useEffect(() => {
    setupTracks();
  }, [participants, setupTracks]);

  return {remoteAudioTracks};
}
