import React, {useEffect, useRef} from 'react';
import useVideoVisitsContext from '../VideoVisits/hooks/useContext';

/**
 * Displays a tile with a non-primary video stream. A non-primary video stream
 * is the video stream from the local user or any other non-video participants.
 * TODO(chhil): Manage multiple video tracks
 */
export function AdditionalVideoPanel() {
  const {videoStreams} = useVideoVisitsContext();
  const videoElRef = useRef(null);
  useEffect(() => {
    videoStreams?.forEach(stream => {
      videoElRef.current && stream.attach(videoElRef.current);
    });
  });

  return <video style={{borderRadius: '15px'}} width={250} ref={videoElRef} />;
}
