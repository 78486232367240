import * as React from 'react';
import * as ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import {VideoVisits, VideoVisitsProvider} from './components';
import {VideoVisitsProps} from './components/VideoVisits/VideoVisits.types';

const parcel = (props: VideoVisitsProps) => (
  <VideoVisitsProvider>
    <VideoVisits
      accessToken={props.accessToken}
      onPopoutVideoVisits={props.onPopoutVideoVisits}
      onResizeVideoVisits={props.onResizeVideoVisits}
      expanded={props.expanded}
      onEndVideoVisit={props.onEndVideoVisit}
      onStartVoiceCall={props.onStartVoiceCall}
      onEndVoiceCall={props.onEndVoiceCall}
      voiceCallSid={props.voiceCallSid}
    />
  </VideoVisitsProvider>
);

export default singleSpaReact({
  React,
  ReactDOM,
  rootComponent: parcel,
});
