import {useCallback, useState} from 'react';
import Video from 'twilio-video';

/**
 * useParticipants is the Context hook for all Remote Participants. This
 * hook handles adding and removing participants from the state.
 */
export default function useParticipants() {
  const [currentVoiceCallSid, setCurrentVoiceCallSid] = useState<string>('');
  const [participants, setParticipants] = useState<Video.RemoteParticipant[]>(
    []
  );
  const [audioParticipants, setAudioParticipants] = useState<
    Video.RemoteParticipant[]
  >([]);

  const addParticipant = useCallback(
    (participant: Video.RemoteParticipant) => {
      if (participant.sid === currentVoiceCallSid) {
        setAudioParticipants([...audioParticipants, participant]);
      } else if (
        participants.find(
          currentParticipant => currentParticipant.sid === participant.sid
        ) === undefined
      ) {
        setParticipants([...participants, participant]);
      }
    },
    [participants, audioParticipants, currentVoiceCallSid]
  );

  const removeParticipant = useCallback(
    (participant: Video.RemoteParticipant) => {
      const participantToRemove = participants.findIndex(
        currentParticipant => currentParticipant.sid === participant.sid
      );
      if (participantToRemove !== undefined) {
        const newParticipants = [...participants];
        newParticipants.splice(participantToRemove, 1);
        setParticipants(newParticipants);
      }
    },
    [participants]
  );

  const connectParticipant = useCallback(
    (participant: Video.RemoteParticipant) => {
      addParticipant(participant);

      return participant;
    },
    [addParticipant]
  );

  return {
    participants,
    audioParticipants,
    addParticipant,
    removeParticipant,
    connectParticipant,
    currentVoiceCallSid,
    setCurrentVoiceCallSid,
  };
}
