import React, {ReactNode} from 'react';
import * as AutomationIds from '../../../automation';

/**
 * Container for the popup that is placed above
 * the footer control when the button is clicked
 */
export function FooterActionPopup({children}: {children: ReactNode}) {
  return (
    <div
      data-testid={AutomationIds.FOOTER_CONFIRM_ACTION_CONTAINER}
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        width: '200px',
        border: '1px solid #000000',
        borderRadius: '8px',
        padding: '8px',
        boxShadow:
          '0px 1px 2px rgba(60, 64, 67, 0.3), 0px 2px 6px rgba(60, 64, 67, 0.15)',
      }}
    >
      {children}
    </div>
  );
}
