import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import React from 'react';

import * as AutomationIds from '../../../automation';

export function ResizeVideoVisitsActionButton({
  onResizeVideoVisits,
  expanded,
}: {
  onResizeVideoVisits: () => void;
  expanded: boolean;
}) {
  return expanded ? (
    <FullscreenExitIcon
      data-testid={AutomationIds.RESIZE_ICON_MINIMIZE}
      onClick={onResizeVideoVisits}
    />
  ) : (
    <FullscreenIcon
      data-testid={AutomationIds.RESIZE_ICON_MAXIMIZE}
      onClick={onResizeVideoVisits}
    />
  );
}
