import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React from 'react';
import * as AutomationIds from '../../../automation';

export function PopoutVideoVisitsActionButton({
  onPopoutVideoVisits,
}: {
  onPopoutVideoVisits: () => void;
}) {
  return (
    <OpenInNewIcon
      data-testid={AutomationIds.POPOUT_ICON}
      onClick={onPopoutVideoVisits}
    ></OpenInNewIcon>
  );
}
