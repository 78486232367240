import {useCallback, useEffect, useState} from 'react';
import {
  createLocalAudioTrack,
  createLocalTracks,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalParticipant,
  LocalVideoTrack,
  RemoteParticipant,
} from 'twilio-video';

export interface Streams {
  video: LocalVideoTrack[];
  audio: LocalAudioTrack[];
}

export enum TrackType {
  VIDEO = 'video',
  AUDIO = 'audio',
}

/**
 * TODO(chhil): This is more accurately useLocalTracks. Update to reflect
 */
export default function useStreams(
  localParticipant: LocalParticipant | null,
  participants: RemoteParticipant[],
  primaryVideoPanelRef: HTMLVideoElement | null,
  selectedCamera: string,
  selectedMicrophone: string
) {
  const [videoStreams, setVideoStreams] = useState<LocalVideoTrack[] | null>(
    null
  );
  const [audioStreams, setAudioStreams] = useState<LocalAudioTrack[] | null>(
    null
  );

  const [initializingLocalStreams, setInitializingLocalStreams] =
    useState<boolean>(false);

  const disconnectStreams = useCallback(
    (type?: MediaDeviceKind) => {
      if (!type || type === 'audioinput') {
        audioStreams?.forEach(stream => {
          stream.disable();
          stream.stop();
          stream.detach();
        });
      }

      if (!type || type === 'videoinput') {
        videoStreams?.forEach(stream => {
          stream.disable();
          stream.stop();
          stream.detach();
        });
      }
    },
    [audioStreams, videoStreams]
  );

  useEffect(() => {
    if (participants.length === 0 && videoStreams && videoStreams.length > 0) {
      primaryVideoPanelRef && videoStreams[0].attach(primaryVideoPanelRef);
    } else if (videoStreams && videoStreams.length > 0) {
      primaryVideoPanelRef && videoStreams[0].detach(primaryVideoPanelRef);
    }
  });

  useEffect(() => {
    const updateCamera = async () => {
      disconnectStreams('videoinput');
      const newVideoTrack = await createLocalVideoTrack({
        deviceId: {exact: selectedCamera},
      });
      if (localParticipant) {
        videoStreams && localParticipant.unpublishTracks(videoStreams);
        localParticipant.publishTrack(newVideoTrack);
      }

      setVideoStreams([newVideoTrack]);
    };

    if (
      selectedCamera !== '' &&
      videoStreams?.[0] &&
      videoStreams[0].mediaStreamTrack.getSettings().deviceId !== selectedCamera
    ) {
      updateCamera();
    }
  }, [
    disconnectStreams,
    localParticipant,
    selectedCamera,
    videoStreams,
    audioStreams,
  ]);

  useEffect(() => {
    const updateMicrophone = async () => {
      const newAudioTrack = await createLocalAudioTrack({
        deviceId: {exact: selectedMicrophone},
      });

      newAudioTrack.enable();
      if (localParticipant) {
        audioStreams && localParticipant.unpublishTracks(audioStreams);
        localParticipant.publishTrack(newAudioTrack);
      }
      setAudioStreams([newAudioTrack]);
    };
    if (
      selectedMicrophone !== '' &&
      audioStreams?.[0] &&
      audioStreams[0].mediaStreamTrack.getSettings().deviceId !==
        selectedMicrophone
    ) {
      updateMicrophone();
    }
  }, [
    selectedMicrophone,
    audioStreams,
    videoStreams,
    disconnectStreams,
    localParticipant,
  ]);

  const [localVideoStreamEnabled, setLocalVideoStreamEnabled] = useState(true);
  const [localAudioStreamEnabled, setLocalAudioStreamEnabled] = useState(true);

  const startLocalStream = useCallback(async () => {
    if (initializingLocalStreams || videoStreams || audioStreams)
      return Promise.resolve();
    setInitializingLocalStreams(true);

    try {
      const trackConstraints = {audio: true, video: {facingMode: 'user'}};
      const localTracks = await createLocalTracks(trackConstraints);
      const streams: Streams = {
        video: [],
        audio: [],
      };
      localTracks.forEach(track => {
        if (track.kind === TrackType.VIDEO) {
          streams.video.push(track);
        }

        if (track.kind === TrackType.AUDIO) {
          streams.audio.push(track);
        }
      });
      setVideoStreams(streams.video);
      setAudioStreams(streams.audio);
    } catch (error) {
      /**
       * TODO(chhil): handle errors
       */
    }
  }, [audioStreams, initializingLocalStreams, videoStreams]);

  /**
   * Disable/Enable camera
   */
  function handleLocalVideoTrack() {
    const newValue = !localVideoStreamEnabled;
    setLocalVideoStreamEnabled(newValue);
    /**
     * TODO(chhil): handle member video separately
     */
    if (!newValue) {
      videoStreams?.forEach(stream => {
        stream.disable();
      });
    } else {
      videoStreams?.forEach(stream => {
        stream.enable();
      });
    }
  }

  /**
   * Disable/Enable microphone
   */
  function handleLocalAudioTrack() {
    const newValue = !localAudioStreamEnabled;
    setLocalAudioStreamEnabled(newValue);
    /**
     * TODO(chhil): handle member video separately
     */
    if (!newValue) {
      audioStreams?.forEach(stream => {
        stream.disable();
      });
    } else {
      audioStreams?.forEach(stream => {
        stream.enable();
      });
    }
  }

  return {
    audioStreams,
    videoStreams,
    localVideoStreamEnabled,
    localAudioStreamEnabled,
    startLocalStream,
    disconnectStreams,
    handleLocalVideoTrack,
    handleLocalAudioTrack,
  };
}
