import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import * as AutomationIds from '../../automation';
import useVideoVisitsContext from '../VideoVisits/hooks/useContext';

export function SettingsPanel() {
  const {
    setSettingsPanelOpen,
    selectedMicrophone,
    setSelectedMicrophone,
    selectedCamera,
    setSelectedCamera,
  } = useVideoVisitsContext();

  /**
   * Set the currently selected option in the camera dropdown.
   * Initialize it with the currently selected camera
   */
  const [selectedCameraOption, setSelectedCameraOption] =
    useState<string>(selectedCamera);

  /**
   * Set the currently selected option in the microphone dropdown.
   * Initialize it with the currently selected microphone
   */
  const [selectedMicrophoneOption, setSelectedMicrophoneOption] =
    useState<string>(selectedMicrophone);

  const [microphoneOptions, setMicrophoneOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const [cameraOptions, setCameraOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const handleCameraSelect = (event: SelectChangeEvent) => {
    setSelectedCameraOption(event.target.value as string);
  };

  const handleMicrophoneSelect = (event: SelectChangeEvent) => {
    setSelectedMicrophoneOption(event.target.value as string);
  };

  /**
   * Set the application's selected camera and microphone on save
   */
  const handleSave = () => {
    setSelectedCamera(selectedCameraOption);
    setSelectedMicrophone(selectedMicrophoneOption);
    setSettingsPanelOpen(false);
  };

  const getAvailableMediaDevices = useCallback(async () => {
    const availableMicrophones: {
      label: string;
      value: string;
    }[] = [];
    const availableCameras: {
      label: string;
      value: string;
    }[] = [];
    const devices = await navigator.mediaDevices.enumerateDevices();

    devices.forEach(device => {
      if (device.kind === 'audioinput' && device.deviceId !== 'default') {
        availableMicrophones.push({
          label: device.label,
          value: device.deviceId,
        });
      }
      if (device.kind === 'videoinput' && device.deviceId !== 'default') {
        availableCameras.push({
          label: device.label,
          value: device.deviceId,
        });
      }
    });
    setCameraOptions(availableCameras);
    setMicrophoneOptions(availableMicrophones);
    if (selectedCamera === '') {
      setSelectedCameraOption(availableCameras[0].value);
    }
    if (selectedMicrophone === '') {
      setSelectedMicrophoneOption(availableMicrophones[0].value);
    }
  }, [selectedCamera, selectedMicrophone]);

  useEffect(() => {
    getAvailableMediaDevices();
  }, [getAvailableMediaDevices]);

  useEffect(() => {
    navigator.mediaDevices.ondevicechange = () => {
      getAvailableMediaDevices();
    };
  }, [getAvailableMediaDevices]);

  return (
    <Dialog
      data-testid={AutomationIds.SETTINGS_DIALOG}
      onClose={() => setSettingsPanelOpen(false)}
      open={true}
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent
        style={{
          width: '400px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '16px',
          }}
        >
          <FormControl variant='filled'>
            <InputLabel
              sx={{color: '#546E7A'}}
              data-testid={AutomationIds.CAMERA_SELECTION_DROPDOWN_LABEL}
              id={AutomationIds.CAMERA_SELECTION_DROPDOWN_LABEL}
            >
              Camera
            </InputLabel>
            <Select
              sx={{
                bgcolor: '#ECEFF1',
                borderRadius: '8px',
              }}
              disabled={cameraOptions.length === 0}
              data-testid={AutomationIds.CAMERA_SELECTION_DROPDOWN}
              labelId={AutomationIds.CAMERA_SELECTION_DROPDOWN_LABEL}
              value={selectedCameraOption}
              onChange={handleCameraSelect}
            >
              {cameraOptions.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <FormControl variant='filled'>
            <InputLabel
              sx={{color: '#546E7A'}}
              data-testid={AutomationIds.MICROPHONE_SELECTION_DROPDOWN_LABEL}
              id={AutomationIds.MICROPHONE_SELECTION_DROPDOWN_LABEL}
            >
              Microphone
            </InputLabel>
            <Select
              sx={{
                bgcolor: '#ECEFF1',
                borderRadius: '8px',
              }}
              disabled={microphoneOptions.length === 0}
              data-testid={AutomationIds.MICROPHONE_SELECTION_DROPDOWN}
              labelId={AutomationIds.MICROPHONE_SELECTION_DROPDOWN_LABEL}
              value={selectedMicrophoneOption}
              onChange={handleMicrophoneSelect}
            >
              {microphoneOptions.map(item => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setSettingsPanelOpen(false)}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
