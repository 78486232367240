import React from 'react';
import * as AutomationIds from '../../../automation';
import {FooterIconProps} from './FooterIcon.types';

export const FooterIcon = ({icon, backgroundColor}: FooterIconProps) => {
  return (
    <div
      data-testid={AutomationIds.FOOTER_CONTROL_ICON_BACKGROUND}
      style={{
        height: 48,
        width: 48,
        borderRadius: '24px',
        border: `1px solid ${backgroundColor || '#E0E0E0'}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor,
      }}
    >
      {icon}
    </div>
  );
};
