import {useState} from 'react';
import Video, {LocalParticipant} from 'twilio-video';

export default function useLocalParticipant() {
  const [localParticipant, setLocalParticipant] =
    useState<Video.LocalParticipant | null>(null);

  function addLocalParticipant(participant: LocalParticipant) {
    setLocalParticipant(participant);
  }

  return {addLocalParticipant, localParticipant};
}
