import React from 'react';
import {PopoutVideoVisitsActionButton} from '../PopoutVideoActionButton';
import {ResizeVideoVisitsActionButton} from '../ResizeVideoVisitsActionButton';

export function ResizeActionsToolbar({
  onPopoutVideoVisits,
  onResizeVideoVisits,
  expanded,
}: {
  onPopoutVideoVisits?: () => void;
  onResizeVideoVisits?: () => void;
  expanded: boolean;
}) {
  return (
    <div
      style={{
        height: '30px',
        width: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '30px',
        color: 'white',
      }}
    >
      {onPopoutVideoVisits && (
        <div style={{height: '20px'}}>
          <PopoutVideoVisitsActionButton
            onPopoutVideoVisits={onPopoutVideoVisits}
          />
        </div>
      )}
      {onResizeVideoVisits && (
        <div style={{height: '20px'}}>
          <ResizeVideoVisitsActionButton
            onResizeVideoVisits={onResizeVideoVisits}
            expanded={expanded}
          />
        </div>
      )}
    </div>
  );
}
