import {useCallback, useEffect, useState} from 'react';
import * as Video from 'twilio-video';

/**
 * The Context hook for remote video tracks. When a participant is added,
 * this hook stores their video tracks in its state. When a participant is
 * removed, the video tracks are detached from their video elements
 * and removed from the state.
 */
export default function useRemoteVideoTracks(
  participants: Video.RemoteParticipant[],
  primaryVideoPanelRef: HTMLVideoElement | null
) {
  const [remoteVideoTracks, setRemoteVideoTracks] = useState<
    Map<string, Video.RemoteVideoTrack>
  >(new Map<string, Video.RemoteVideoTrack>());

  /**
   * Callback function, triggered whenever participants are updated.
   * Handles initializing tracks and their listeners.
   */
  const setupTracks = useCallback(() => {
    participants.forEach(participant => {
      /**
       * Tracks must be both subscribed and enabled to be rendered.
       */
      participant.on('trackSubscribed', (track: Video.RemoteTrack) => {
        if (track.kind === 'video') {
          /**
           * If the participant joins and their video is already enabled,
           * render it right away.
           */
          if (track.isEnabled) {
            primaryVideoPanelRef && track.attach(primaryVideoPanelRef);
            setRemoteVideoTracks(
              new Map(remoteVideoTracks.set(participant.sid, track))
            );
          }

          /**
           * If the participant joins and their video is not enabled,
           * we need to wait until it is enabled to render it.
           * Or, if it's initially enabled but then they disable it,
           * we need to pick up the case where they re-enable it
           */
          track.on('enabled', (track: Video.RemoteTrack) => {
            if (track.kind === 'video') {
              setRemoteVideoTracks(
                new Map(remoteVideoTracks.set(participant.sid, track))
              );
              primaryVideoPanelRef && track.attach(primaryVideoPanelRef);
            }
          });

          /**
           * When the participant disables their video through the camera
           * control, we remove the track from state and detach it from the
           * media element it is attached to.
           */
          track.on('disabled', (track: Video.RemoteTrack) => {
            if (track.kind === 'video') {
              const newMap = new Map(remoteVideoTracks);
              newMap.delete(participant.sid);
              primaryVideoPanelRef && track.detach(primaryVideoPanelRef);
              setRemoteVideoTracks(newMap);
            }
          });
        }
      });

      /**
       * Remove tracks and detach them from their media elements
       * when they are unsubscribed.
       */
      participant.on('trackUnsubscribed', track => {
        if (track.kind === 'video') {
          const newMap = new Map(remoteVideoTracks);
          newMap.delete(participant.sid);
          primaryVideoPanelRef && track.detach(primaryVideoPanelRef);
          setRemoteVideoTracks(newMap);
        }
      });
    });
  }, [participants, remoteVideoTracks, primaryVideoPanelRef]);

  /**
   * This useEffect is triggered whenever participants are updated,
   * calling setupTracks to handle the new participants
   */
  useEffect(() => {
    setupTracks();
  }, [participants, setupTracks]);

  return {remoteVideoTracks};
}
