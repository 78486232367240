export const FOOTER_CONTROL_CLICK_HANDLER = 'footer-control-click-handler';
export const FOOTER_CONTROL_ICON_BACKGROUND = 'footer-control-icon-background';
export const FOOTER_CONFIRM_ACTION_CONTAINER =
  'footer-confirm-action-container';
export const FOOTER_CONFIRM_ACTION_CONFIRM_BUTTON =
  'footer-confirm-action-confirm-button';
export const FOOTER_CONFIRM_ACTION_CANCEL_BUTTON =
  'footer-confirm-action-cancel-button';
export const CAMERA_SELECTION_DROPDOWN = 'camera-selection-dropdown';
export const CAMERA_SELECTION_DROPDOWN_LABEL =
  'camera-selection-dropdown-label';
export const MICROPHONE_SELECTION_DROPDOWN = 'microphone-selection-dropdown';
export const MICROPHONE_SELECTION_DROPDOWN_LABEL =
  'microphone-selection-dropdown-label';
export const SETTINGS_DIALOG = 'settings-dialog';
export const OPEN_SETTINGS_MENU = 'open-settings-menu';
export const TRIGGER_EXTERNAL_CALL = 'trigger-external-call';
export const VIDEOVISITS = 'video-visits';
export const RESIZE_ICON_MAXIMIZE = 'resize-icon-maximize';
export const RESIZE_ICON_MINIMIZE = 'resize-icon-minimize';
export const POPOUT_ICON = 'popout-icon';
